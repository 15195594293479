/* Global styles for the body element, setting the margin to zero and defining font properties */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Styles for the root element of the React application, centering its content and defining background color */
#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #282c34;
}

/* Styles for button elements, defining the font size and dimensions */
button {
  font-size: 2em;
  width: 100px;
  height: 100px;
}

/* Styles for the timeButton class, defining the margin and width */
.timeButton {
  margin: 5px;
  width: 145px;
}

/* Styles for the header class, defining the width */
.header {
  width: 310px;
}
